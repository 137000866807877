<script>
  import { onMount } from "svelte"
  import { formatDec } from "../../utilities/format"
  export let products = []
  export let prices = []
  export let form_prefix_name = "payment_service"
  export let quantity = 4
  export let interval = "year"

  let total = 0
  let savings = 0
  quantity = parseInt(quantity)
  if (quantity < 1) {
    quantity = 4
  }
  if (!["year", "month"].includes(interval)) {
    interval = "year"
  }

  let price = null
  let product = null
  let max_users = 1

  prices.forEach((price) => {
    price.interval = price.recurring.interval
  })
  products.forEach((prod) => {
    prod.max_users = +prod.metadata.max_users
    prod.min_users = +prod.metadata.min_users
    if (prod.max_users > max_users) {
      max_users = prod.max_users
    }
    prod.selected = prod.min_users <= quantity && quantity <= prod.max_users
    const prodPrices = prices.filter((p) => p.product === prod.id)

    prod.prices = prodPrices
  })

  const intervalName = {
    year: "Annual",
    month: "Monthly",
  }

  export function getData() {
    return {
      quantity,
      interval,
    }
  }

  function toggleInterval() {
    interval = interval === "year" ? "month" : "year"
    products = products
    setPrice()
  }

  function getPriceMonth(product) {
    const price = product.prices.find((p) => p.interval === interval)
    if (price.interval === "year") {
      return price.unit_amount / 100 / 12
    } else {
      return price.unit_amount / 100
    }
  }

  function getPriceYear(product) {
    const price = product.prices.find((p) => p.interval === interval)
    if (price.interval === "year") {
      return price.unit_amount / 100
    } else {
      return (price.unit_amount * 12) / 100
    }
  }

  const screenWidth = 768

  function setPrice() {
    product = products.find((p) => p.selected)
    if (interval === "month") {
      price = product.prices.find((p) => p.interval === "month")
    } else {
      price = product.prices.find((p) => p.interval === "year")
    }
  }

  function selectProduct(product) {
    quantity = product.min_users
  }

  $: {
    total = (price?.unit_amount / 100) * quantity
    if (interval === "year" && product) {
      const p_month = product.prices.find((p) => p.interval === "month")
      savings = (p_month.unit_amount / 100) * quantity * 12 - total
    }
    products.forEach((prod) => {
      prod.selected = prod.min_users <= quantity && quantity <= prod.max_users
    })
    products = products
  }

  onMount(() => {
    setPrice()
  })
</script>

<div class="px-6 py-4 w-full">
  <div class="mb-2">
    <div class="text-center text-2xl font-semibold text-blue-600">
      {quantity} Members
    </div>
    <input
      type="range"
      bind:value={quantity}
      class="w-full"
      min="1"
      max={max_users}
    />
  </div>
  <!--Hideen values for the form-->
  <input
    type="hidden"
    bind:value={quantity}
    name="{form_prefix_name}[quantity]"
  />
  <input
    type="hidden"
    bind:value={interval}
    name="{form_prefix_name}[interval]"
  />

  <div>
    <div
      class="rounded-full relative bg-blue-100 border mx-auto border-blue-100 h-12 flex mb-4 justify-self-end items-center"
      style="width:300px;"
    >
      <button
        class="px-8 py-3 font-semibold text-gray-600 inline-block absolute cursor-pointer"
        class:hidden={interval === "year"}
        type="button"
        on:click={toggleInterval}
      >
        Annual
      </button>
      <button
        type="button"
        on:click={toggleInterval}
        class="rounded-full px-16 py-3 bg-blue-600 text-white font-semibold transition-all duration-200"
        style="transform: translateX({interval === 'year' ? '0' : '110'}px)"
      >
        {intervalName[interval]}
      </button>
      <button
        class="px-8 py-3 font-semibold text-gray-600 inline-block absolute right-1 cursor-pointer"
        class:hidden={interval === "month"}
        on:click={toggleInterval}
        type="button"
      >
        Monthly
      </button>
    </div>
  </div>

  <div class="grid grid-cols-3 gap-4 plans">
    {#each products as product}
      <button
        class="drop-shadow-md rounded-lg px-2 border bg-white text-center py-8 plan cursor-pointer"
        class:!bg-blue-600={product.selected}
        class:text-white={product.selected}
        on:click={() => selectProduct(product)}
        type="button"
      >
        <div class="mb-4 text-xl">{product.name}</div>
        <div class="text-3xl">${formatDec(getPriceMonth(product))}</div>
        <div class="text-sm">
          <span class="font-semibold">${formatDec(getPriceYear(product))}</span>
          per member/year
        </div>
      </button>
    {/each}
  </div>

  <div class="pt-8 text-center">
    <div class="text-gray-500">
      <span>
        Total Amount <strong>{interval}</strong>
      </span>
      <span class="text-xl text-gray-700 font-semibold"
        >${formatDec(total)}</span
      >
      for {quantity} member{quantity > 1 ? "s" : ""}
    </div>
    {#if interval === "year"}
      <div class="text-gray-500 text-sm">
        You save <strong>${formatDec(savings)}</strong> in a year
      </div>
    {/if}
  </div>
</div>
