import { Controller } from "@hotwired/stimulus"
import IndividualRegistration from "@/pages/individual_player_registration/index.svelte"

export default class extends Controller {
  connect() {
    const serv = new IndividualRegistration({
      target: this.element,
      props: {
        assessment_code: this.element.dataset.assessment_code,
        stripe_publish_key: this.element.dataset.stripe_plublish_key,
        price: JSON.parse(window.atob(this.element.dataset.price)),
        coupon: this.element.dataset.coupon,
        courses: JSON.parse(window.atob(this.element.dataset.courses)),
      },
    })
  }

  disconnect() {
    this.element.innerHTML = ""
  }
}
