import { Controller } from "@hotwired/stimulus"
import ImageCropper from "../svelte/components/ModelAvatar.svelte"

export default class extends Controller {
  static targets = ["season_start", "season_end", "off_season_start", "off_season_end"]

  change() {
    if(!this.season_start || !this.season_end) {
      return
    }

    if(this.season_start < this.season_end) {
      if(!this.off_season_start) {
        this.setOffSeasonStart()
      }
      if(!this.off_season_end) {
        this.setOffSeasonEnd()
      }
    }
  }

  setOffSeasonStart() {
    this.off_season_start = new Date(this.season_end.getTime() + 24 * 3600 * 1000)
  }

  setOffSeasonEnd() {
    this.off_season_end = new Date(this.season_start.getFullYear() + 1, this.season_start.getMonth(), this.season_start.getDate())
  }

  get season_start() {
    return this.season_startTarget.valueAsDate
  }

  get season_end() {
    return this.season_endTarget.valueAsDate
  }

  get off_season_start() {
    this.off_season_startTarget.valueAsDate
  }
  set off_season_start(date) {
    this.off_season_startTarget.valueAsDate = date
  }

  set off_season_end(date) {
    this.off_season_endTarget.valueAsDate = date
  }
}