import { Controller } from "@hotwired/stimulus"
import PriceSelector from "@/svelte/components/PriceSelector.svelte"

export default class extends Controller {
  connect() {
    const priceSelector = new PriceSelector({
      target: this.element,
      props: {
        inputName: "payment_service[stripe_price_id]",
        prices: JSON.parse(window.atob(this.element.dataset.prices)),
        products: JSON.parse(window.atob(this.element.dataset.products)),
        price_id: this.element.dataset.stripe_price_id,
        quantity: +this.element.dataset.quantity,
        interval: this.element.dataset.interval,
        form_prefix_name: this.element.dataset.prefix_name,
      },
    })
  }

  disconnect() {
    this.element.innerHTML = ""
  }
}
