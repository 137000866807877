import axios from "axios"

export const xhr = axios.create({
  headers: {
    "X-CSRF-TOKEN":
      document.querySelector("meta[name=csrf-token]").content || "",
  },
})

export function wait(ms: number) {
  return new Promise((r) => setTimeout(r, ms))
}

export function throttle(mainFunction: Function, delay: number) {
  let timerFlag = null // Variable to keep track of the timer

  // Returning a throttled version
  return (...args: any[]) => {
    if (timerFlag === null) {
      // If there is no timer currently running
      mainFunction(...args) // Execute the main function
      timerFlag = setTimeout(() => {
        // Set a timer to clear the timerFlag after the specified delay
        timerFlag = null // Clear the timerFlag to allow the main function to be executed again
      }, delay)
    }
  }
}
