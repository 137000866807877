import { Controller } from "@hotwired/stimulus"
import TeamPie from "@/svelte/components/TeamPie.svelte"

export default class extends Controller {
  connect() {
    new TeamPie({
      target: this.element,
      props: {
        leaderships: JSON.parse(window.atob(this.element.dataset.leaderships)),
        players: JSON.parse(window.atob(this.element.dataset.players)),
        radius: +this.element.dataset.radius,
        clickPlayers: false,
      },
    })
  }

  disconnect() {
    this.element.innerHTML = ""
  }
}
