import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  close(e) {
    const div = e.target.parentElement.parentElement
    div.classList.add("scale-0")
    setTimeout(() => { div.remove() }, 500)
  }

  disconnect() {}
}