import { Controller } from "@hotwired/stimulus"
import Registration from "../svelte/Pages/Registration/Form.svelte"

export default class extends Controller {
  connect() {
    new Registration({
      target: this.element,
      props: {
        stripePublishKey: this.element.dataset.stripePublishKey,
        interval: this.element.dataset.interval,
        quantity: this.element.dataset.quantity,
        authenticityToken: this.element.dataset.authenticityToken,
        total: +this.element.dataset.total,
        coupon: this.element.dataset.coupon,
      },
    })
  }

  disconnect() {
    this.element.innerHTML = ""
  }
}
