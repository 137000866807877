import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["states"]

  async loadStates(e) {
    fetch(`/states?country=${e.target.value}&target=${this.statesTarget.id}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(r => r.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }

}