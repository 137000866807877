<script lang="ts">
  import { Eye, EyeOff } from "lucide-svelte"
  export let viewPassword = false
  export let value = ""

  function setValue(e: any): void {
    value = e.target.value
  }
</script>

<div class="relative">
  {#if viewPassword}
    <input
      type="text"
      {value}
      name="password"
      class={$$props.class || ""}
      placeholder="**********"
      on:input={setValue}
    />
  {:else}
    <input
      type="password"
      {value}
      name="password"
      class={$$props.class || ""}
      placeholder="**********"
      on:input={setValue}
    />
  {/if}
  <button
    type="button"
    class="absolute top-2 right-3"
    on:click={() => (viewPassword = !viewPassword)}
  >
    {#if viewPassword}
      <EyeOff class="text-slate-500" />
    {:else}
      <Eye class="text-slate-500" />
    {/if}
  </button>
</div>
