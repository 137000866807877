import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setActiveMenuItem()

    window.addEventListener('click',(e) => {
      if (this.element.classList.contains("active") && !this.element.contains(e.target)){
        this.toggle()
      }
    })
  }


  toggle() {
    this.element.classList.toggle("active")
  }

  setActiveMenuItem() {
    const elem = this.element.querySelector(`.${this.element.dataset.active_menu}-link`)
    if(!elem) {
      return
    }

    elem.classList.add("active")
    elem.querySelectorAll("path").forEach((el) => {
      el.setAttribute("fill", "white")
    })
  }
}