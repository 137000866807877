<script lang="ts">
  import { onMount } from "svelte"
  import RangeSlider from "svelte-range-slider-pips"
  import { formatDec } from "../../utilities/format"
  import bgImg from "@assets/images/bg-enterprise.png"
  import { check } from "../../icons"
  export let products = []
  export let individual_products = []
  export let prices = []
  export let quantity = 4
  export let interval = "year"
  export let coupon = ""

  let total = 0
  let savings = 0
  quantity = Math.floor(quantity)
  if (quantity < 1) {
    quantity = 4
  }
  if (!["year", "month"].includes(interval)) {
    interval = "year"
  }

  let price = null
  let product = null
  let max_users = 1
  let selectedTab = "team"

  prices.forEach((price) => {
    price.interval = price?.recurring?.interval
  })
  products.forEach((prod) => {
    prod.max_users = +prod.metadata.max_users
    prod.min_users = +prod.metadata.min_users
    if (prod.max_users > max_users) {
      max_users = prod.max_users
    }
    prod.selected = prod.min_users <= quantity && quantity <= prod.max_users
    const prodPrices = prices.filter((p) => p.product === prod.id)

    prod.prices = prodPrices
  })

  const individual_product = individual_products[0]

  individual_product.price = prices.find(
    (p) => p.product === individual_product.id,
  )

  function updatePlan() {
    products.forEach((prod) => {
      prod.selected = prod.min_users <= quantity && quantity <= prod.max_users
    })
    products = products
    setPrice()
  }

  const intervalName = {
    year: "Annual",
    month: "Monthly",
  }

  function toggleInterval() {
    interval = interval === "year" ? "month" : "year"
    products = products
    setPrice()
  }

  function getPriceMonth(product) {
    const price = product.prices.find((p) => p.interval === interval)
    if (price.interval === "year") {
      return price.unit_amount / 100 / 12
    } else {
      return price.unit_amount / 100
    }
  }

  function getPriceYear(product) {
    const price = product.prices.find((p) => p.interval === interval)
    if (price.interval === "year") {
      return price.unit_amount / 100
    } else {
      return (price.unit_amount * 12) / 100
    }
  }

  function setPrice() {
    product = products.find((p) => p.selected)
    if (interval === "month") {
      price = product.prices.find((p) => p.interval === "month")
    } else {
      price = product.prices.find((p) => p.interval === "year")
    }
  }

  function getProductCSS(product) {
    if (product.selected) {
      return "text-white !bg-blue-600 md:-my-4"
    } else {
      return ""
    }
  }

  onMount(() => {
    setPrice()
  })
</script>

<div
  class="flex gap-4 text-gray-400 mx-auto justify-center mt-10 tabs border-0"
>
  <button
    on:click={() => (selectedTab = "team")}
    class:selected-tab={selectedTab === "team"}
    class="btn btn-sm"
  >
    Team Plan
  </button>
  <button
    on:click={() => (selectedTab = "individual")}
    class:selected-tab={selectedTab === "individual"}
    class="btn btn-sm"
  >
    Individual Plan
  </button>
</div>

<div
  class="px-6 py-4 mx-auto"
  style="max-width:64rem"
  class:hidden={selectedTab !== "team"}
>
  <div class="mt-4 md:max-w-md mx-auto">
    <div class="text-center text-2xl font-semibold text-white">
      {quantity} Members
    </div>
    <RangeSlider
      values={[quantity]}
      min={1}
      max={300}
      on:change={(e) => {
        quantity = e.detail.value
        updatePlan()
      }}
    />
  </div>

  <div
    class="grid grid-cols-1 justify-center items-start md:grid-cols-3 gap-4 plans mt-20 mx-auto px-8 md:px-0"
  >
    {#each products as product}
      <div
        class="drop-shadow-md rounded-lg bg-white mx-auto max-w-sm text-center w-full pt-8 pb-4 plan transition-all duration-300 {getProductCSS(
          product,
        )}"
      >
        <div class="mb-4 text-4xl font-mono uppercase">{product.name}</div>
        <div class="text-xl">
          <span class="font-semibold">${formatDec(getPriceYear(product))}</span>
          per member/year
        </div>
        <ul class="text-left px-6 lg:px-10 text-sm lg:text-base mt-4">
          <li class="py-1">
            {@html check}
            {product.min_users}-{product.max_users} Members
          </li>
          <li class="py-1">
            {@html check} Assessment
          </li>
          <li class="py-1">
            {@html check} Individual Results
          </li>
          <li class="py-1">
            {@html check} Team Results
          </li>
          <li class="py-1">
            {@html check} Player Dashboard
          </li>
          <li class="py-1">
            {@html check} Coach Dashboard
          </li>
        </ul>

        <a
          href="/registrations/new?quantity={product.selected
            ? quantity
            : product.min_users}&interval={interval}{coupon !== ''
            ? `&coupon=${coupon}`
            : ''}"
          class="py-2 px-2 mx-4 text-white bg-blue-700 block mt-10 hover:text-gray-200 hover:bg-blue-800"
        >
          Choose {product.name}
        </a>
      </div>
    {/each}
  </div>

  <div
    class="bg-white p-10 rounded-2xl mt-12 mx-auto"
    style="max-width:810px;background-image:url('{bgImg}');background-repeat:no-repeat;background-position: 140px 0px"
  >
    <div class="mb-4 text-4xl font-mono uppercase">ENTERPRISE PLAN</div>
    <div class="grid grid-cols-3 lg:grid-cols-2">
      <div class="col-span-2 lg:col-span-1" style="max-width: 380px;">
        <p>
          If you need more than what the plans above offer please reach out to
          our sales team. They’ll be more than happy to provide a quote on our
          flexible size-based pricing.
        </p>
        <a
          class="py-2 px-8 text-white bg-blue-700 inline-block mt-10 hover:text-gray-200 hover:bg-blue-800"
          href="/contact"
        >
          Contact Sales
        </a>
      </div>
      <div class="flex justify-end">
        <div
          class="rounded-full w-28 h-28 border border-blue-500 bg-white items-center grid grid-cols-1 text-center"
        >
          <div class="leading-3">
            <div class="text-3xl">300+</div>
            <div>Members</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="bg-white rounded-2xl p-10 mt-8 mb-8 mx-auto max-w-lg"
  class:hidden={selectedTab !== "individual"}
>
  <div class="upppercase font-mono text-4xl text-center">
    {individual_product.name}
  </div>

  <div class="text-xl text-center">
    <span class="font-semibold">
      ${formatDec(individual_product.price.unit_amount / 100)}
    </span>
    per assessment
  </div>

  <ul class="text-left px-6 lg:px-10 text-sm lg:text-base mt-4">
    <li class="py-1">
      {@html check} Assessment
    </li>
    <li class="py-1">
      {@html check} Individual Results
    </li>
    <li class="py-1">
      {@html check} Player Dashboard
    </li>
    <li class="py1">
      {@html check} 75 min Captain Your Life Webinar
    </li>
  </ul>
  <div>
    <a
      href="/individual_player_registration"
      class="py-2 px-2 mx-4 text-white text-center bg-blue-700 block mt-10 hover:text-gray-200 hover:bg-blue-800"
    >
      Choose
    </a>
  </div>
</div>

<style lang="postcss">
  .tabs button {
    @apply text-gray-400 font-semibold;
  }
  .tabs .selected-tab {
    @apply text-white font-semibold bg-blue-600;
  }

  li {
    @apply flex items-center gap-1;
  }
</style>
