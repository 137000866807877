// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import "../controllers/application"
import Swiper from "swiper/bundle"
import Accordion from "accordion-js"
import { SvelteToast } from "@zerodevx/svelte-toast"
import { createInertiaApp } from "@inertiajs/svelte"
import "./application.scss"

const toastApp = new SvelteToast({
  // Set where the toast container should be appended into
  target: document.body,
  props: {
    options: {},
  },
})

window.Swiper = Swiper
window.Accordion = Accordion

document.addEventListener("turbo:load", function () {
  const swiper = new Swiper(".swiper", {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,

      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>'
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    speed: 800,
    keyboard: true,
  })

  const swiper_trusted = new Swiper(".swiper-trusted", {
    autoplay: {
      delay: 1000,
    },
    slidesPerView: "auto",
    spaceBetween: 20,
    loop: true,
    speed: 800,
    keyboard: true,
  })
})

const pages = import.meta.glob("../pages/**/*.svelte")

createInertiaApp({
  resolve: async (name) => {
    if (name) {
      const page = await pages[`../pages/${name}.svelte`]()
      return page
    } else {
      window.location.reload()
      return
    }
  },
  setup(resp) {
    const { el, App, props } = resp
    const app = new App({ target: el, props })
  },
})
