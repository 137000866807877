import { Controller } from "@hotwired/stimulus"
import {formatDec} from "../../utilities/format"

export default class extends Controller {
  static targets = ["pricelist", "pricecont", "period", "priceInput", "planButton", "productDescription", "priceYear", "priceMonth"]
  static values = { prices: Array, products: Array }

  initialize() {
    this.isOpen = false
    this.price = this.findPrice(this.priceInputTarget.value)
    this.product = this.findProduct(this.price.product)

    document.addEventListener("click", (event) => {
      if (!event.composedPath().includes(this.pricecontTarget) && this.isOpen) {
        this.toggle()
      }
    })
  }

  toggle() {
    const list = this.pricelistTarget
    if (list.classList.contains("scale-0")) {
      list.classList.remove("scale-0")
      list.classList.add("scale-100")
      this.isOpen = true
    } else {
      list.classList.remove("scale-100")
      list.classList.add("scale-0")
      this.isOpen = false
    }
  }

  setValue(e) {
    this.product = this.findProduct(e.target.dataset.value)
    //this.price = this.findPrice(e.target.dataset.value)

    //this.priceInputTarget.value = price.id
    this.planButtonTarget.innerText = e.target.innerText
    this.productDescriptionTarget.innerText = e.target.dataset.description
    this.toggle()
  }

  findPrice(id) {
    return this.pricesValue.find(p => p.id === id)
  }

  findPriceProd(product) {

  }

  findProduct(id) {
    return this.productsValue.find(p => p.id === id)
  }

  togglePeriod(e) {
    e.stopPropagation()
  }
}
