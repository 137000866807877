import { Controller } from "@hotwired/stimulus"
import PlanSelector from "../svelte/components/PlanSelector.svelte"

export default class extends Controller {
  connect() {
    const planSelector = new PlanSelector({
      target: this.element,
      props: {
        inputName: "payment_service[stripe_price_id]",
        prices: JSON.parse(window.atob(this.element.dataset.prices)),
        products: JSON.parse(window.atob(this.element.dataset.products)),
        individual_products: JSON.parse(
          window.atob(this.element.dataset.individual_products)
        ),
        interval: this.element.dataset.interval,
        quantity: +this.element.dataset.quantity || 4,
        coupon: this.element.dataset.coupon,
      },
    })
  }

  disconnect() {
    this.element.innerHTML = ""
  }
}
