import { UserType } from "@/types"
import { toast } from "@zerodevx/svelte-toast"

export function formatDec(num: number, decs = 2): string {
  return (Math.round(+num * 100) / 100).toFixed(decs)
}

export function imgSrc(src) {
  return `data:image/svg+xml;charset=UTF-8,${src}`
}

const toastThemes = {
  error: {
    "--toastBackground": "#F56565",
    "--toastBarBackground": "#C53030",
  },
  success: {
    "--toastBackground": "#48BB78",
    "--toastBarBackground": "#2F855A",
  },
  warning: {
    "--toastBackground": "#EAB309",
    "--toastBarBackground": "#FAC62E",
  },
  info: {
    "--toastBackground": "rgb(59 130 246)",
    "--toastBarBackground": "rgb(96 165 250)",
  },
}

export function toastMsg(
  msg: string,
  toastType: string,
  options = { duration: 7000 }
): void {
  const theme = toastThemes[toastType] || {}

  toast.push(msg, { theme: theme, ...options })
}

export function titleize(str: string): string {
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())
}

export function kebabToCamel(str: string): string {
  return str
    .split("-")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("")
}

export function sortUsersByName<T extends UserType>(users: T[]): T[] {
  return users.sort((a, b) => a.name.localeCompare(b.name))
}
