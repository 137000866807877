<script>
  import { check } from "@/icons"

  export let description = ""
</script>

<div class="lg:order-first space-y-10">
  <div class="bg-white py-8 px-10 rounded-2xl flex-1 mt-10 lg:mt-0">
    <h1 class="text-3xl lg:text-4xl font-mono uppercase">
      Start Your Individual Assessment
    </h1>
    <ul class="text-left px-6 lg:px-10 text-sm lg:text-base mt-4">
      <li class="py-1 flex items-center gap-1">
        {@html check} Assessment
      </li>
      <li class="py-1 flex items-center gap-1">
        {@html check} Individual Results
      </li>
      <li class="py-1 flex items-center gap-1">
        {@html check} Player Dashboard
      </li>
      <li class="py1 flex items-center gap-1">
        {@html check} 75 min Captain Your Life Webinar
      </li>
    </ul>
  </div>

  {#if description !== ""}
    <div class="bg-white py-8 px-10 rounded-2xl flex-1 mt-10 lg:mt-0">
      <div class="px-0 py-4 md:px-8 space-y-3">
        {@html description}
      </div>
    </div>
  {/if}
</div>
