<script>
  import { onMount } from "svelte"
  import { Pulse } from "svelte-loading-spinners"
  import axios from "axios"
  import { formatDec } from "@/utilities/format"
  import Password from "@/svelte/components/Password.svelte"

  export let stripePublishKey = ""
  export let total = 0
  export let interval
  export let quantity
  export let authenticityToken = ""
  export let coupon = ""

  let stripe
  let cardNode
  let cardCSS = ""
  let form = { name: "", email: "", password: "", coupon: coupon || "" }
  let errors = {}
  let saving = false
  let chekingCoupon = false
  let couponStatus = ""
  let isFree = false
  let discountPercent = 0
  let cardElement
  let validCoupon = false

  // By some strange reason we need to create a new instance and set the auth token
  let xhr

  //
  function loadStripe() {
    stripe = Stripe(stripePublishKey)
    let elements = stripe.elements()

    let style = {
      base: {
        fontSize: "16px",
        color: "#32325d",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#a0aec0",
        },
      },
    }
    cardElement = elements.create("card", { style: style })
    cardElement.mount(cardNode)

    // Focus, Blur
    cardElement.on("focus", () => (cardCSS = "ring-2 ring-blue-600"))
    cardElement.on("blur", () => (cardCSS = ""))
  }

  //
  async function checkCoupon() {
    if (form.coupon.trim() === "") {
      errors.coupon = ""
      couponStatus = ""
      return
    }

    chekingCoupon = true
    errors.coupon = ""

    try {
      const resp = await xhr.post("/valid_coupon", {
        coupon: form.coupon,
      })
      if (!resp.data.valid) throw "Invalid"

      if (resp.data.percent_off === 100) {
        couponStatus = "free"
      } else {
        couponStatus = "discount"
      }
      discountPercent = resp.data.percent_off
      validCoupon = true
    } catch (e) {
      couponStatus = "invalid"
      errors.coupon = "Invalid coupon"
      couponStatus = ""
      validCoupon = false
    } finally {
      chekingCoupon = false
    }
  }

  //
  async function registerAccount() {
    saving = true
    errors = {}
    try {
      if (couponStatus === "free") {
        form.stripe_token = ""
        form.stripe_card_id = ""
      } else {
        window.cardNode = cardNode
        window.stripe = stripe
        const result = await stripe.createToken(cardElement)
        if (result.error) {
          saving = false
          alert(result.error.message)
          return
          // throw new Error(result.error.message)
        }

        form.stripe_token = result.token.id
        form.stripe_card_id = result.token.card.id
      }

      const resp = await xhr.post("/registrations.json", {
        registration_service: { ...form, interval, quantity },
      })

      window.location = "/teams/new"
    } catch (e) {
      errors = e.response?.data?.errors
      console.log(errors)
      if (errors?.base) {
        alert(errors.base)
      }
    } finally {
      saving = false
    }
  }

  onMount(() => {
    xhr = axios.create({ headers: { "X-CSRF-TOKEN": authenticityToken } })

    let inter = setInterval(() => {
      if (Stripe) {
        clearInterval(inter)
        loadStripe()
      }
    }, 300)

    if (coupon !== "") {
      checkCoupon()
    }
  })
</script>

<div>
  <div class="mb-4 text-3xl lg:text-4xl font-mono uppercase">
    Your Account Details
  </div>

  <div class="text-red-500 font-semibold text-center">{errors.base || ""}</div>

  <div class="input" class:field_with_errors={errors.name}>
    <label for="registration_service_name">Your Full Name</label>
    <input class="w-full" type="text" bind:value={form.name} />
    <div class="text-sm text-red-500">{errors.name || ""}</div>
  </div>

  <div class="input" class:field_with_errors={errors.email}>
    <label for="registration_service_email">Email Address</label>
    <input
      autocomplete="email"
      class="w-full"
      type="email"
      bind:value={form.email}
    />
    <div class="text-sm text-red-500">{errors.email || ""}</div>
  </div>

  <div class="input" class:field_with_errors={errors.password}>
    <label for="registration_service_password">Password</label>
    <Password bind:value={form.password} class="w-full" />
    <div class="text-sm text-red-500">{errors.password || ""}</div>
  </div>

  <div class="mb-4 text-3xl lg:text-4xl font-mono uppercase mt-16">
    Payment Details
  </div>

  <div
    class="border rounded-full p-3 {cardCSS}"
    bind:this={cardNode}
    class:hidden={couponStatus === "free"}
  />

  <div>
    <div class="relative" class:field_with_errors={errors.coupon}>
      <input
        class="w-full mt-2 border"
        class:input-success={validCoupon}
        placeholder="Coupon Code"
        type="text"
        bind:value={form.coupon}
        on:blur={checkCoupon}
      />
      <button
        class="absolute whitespace-nowrap top-2 right-0 px-4 py-2 btn primary btn-sm"
        style="padding-top:10px;padding-bottom:10px"
        disabled={form.coupon === ""}
        on:click={checkCoupon}
      >
        Validate Coupon
      </button>
    </div>
    <div class="text-gray-500 flex items-center" class:hidden={!chekingCoupon}>
      <Pulse color="hsl(215deg 16% 47%)" size="40" duration="800ms" />
      <span class="ml-2">Cheking coupon</span>
    </div>

    <div class="text-sm text-red-500">{errors.coupon || ""}</div>

    <div class="text-sm text-green-600" class:hidden={!validCoupon}>
      Valid coupon
    </div>
  </div>

  <hr class="mt-4" />

  <div class="grid grid-cols-2 py-4">
    <div class="text-left text-gray-500">Total</div>
    <div class="text-right text-gray-600 text-lg font-bold">
      ${formatDec(total)}
    </div>

    <div class="text-left text-gray-500">Discount</div>
    <div class="text-right text-gray-600 text-lg font-bold">
      - ${formatDec((total * discountPercent) / 100.0)}
    </div>

    <hr class="col-span-2" />

    <div class="text-left text-gray-500">You pay each {interval}</div>
    <div class="text-right text-xl font-bold">
      ${formatDec(total - (total * discountPercent) / 100.0)}
    </div>
  </div>

  <button
    class="py-2 px-8 text-center w-full mt-4 text-xl btn primary rounded-none"
    disabled={saving || chekingCoupon}
    on:click={() => registerAccount()}
  >
    {#if saving}
      <div class="flex items-center justify-center">
        <Pulse color="white" />
        <div class="ml-2 text-gray-200">Creating account</div>
      </div>
    {:else}
      Sign Up
    {/if}
  </button>
</div>
