import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const self = this

    this.element.onload = function () {
      setTimeout(() => self.loadedImg(), 10)
    }
    if (this.element.complete) {
      setTimeout(() => self.loadedImg(), 10)
    }
  }

  loadedImg() {
    this.element.classList.remove("hidden")
    const inter = setInterval(() => {
      if (this.element.style.opacity >= 0.9) {
        clearInterval(inter)
      } else {
        this.element.style.opacity = +this.element.style.opacity + 0.015
      }
    }, 10)
  }

  get isLoaded() {
    console.log("Img loaded")
  }
}
