import { Application } from "@hotwired/stimulus"
import PlanSelect from "./components/plan_select"
import PriceSelector from "./price_selector_controller"
import MenuCont from "./menu_controller"
import Flash from "./flash_controller"
import SeasonDates from "./season_dates_controller"
import CountryController from "./country_controller"
import RegistrationController from "./registration_controller"
import TeamPieController from "./team_pie_controller"
import PlanSelector from "./plan_selector_controller"
import ImageTransition from "./image_transition"
import Card from "./card_controller"
import IndividualRegistration from "./individual_registration_controller"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

application.register("plan_select", PlanSelect)
application.register("price-selector-svelte", PriceSelector)
application.register("menu", MenuCont)
application.register("flash", Flash)
application.register("season-dates", SeasonDates)
application.register("country", CountryController)
application.register("registration", RegistrationController)
application.register("team-pie", TeamPieController)
application.register("plan-selector", PlanSelector)
application.register("image-transition", ImageTransition)
application.register("card", Card)
application.register("individual-registration", IndividualRegistration)

export { application }
