import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ["card", "exp", "cvv"]

  connect() {
    // Card
    IMask(this.cardTarget, {mask: "0000 0000 0000 0000"})
    // Exp date
    IMask(this.expTarget, {mask: "00/00"})
    // CVC
    IMask(this.cvvTarget, {mask: /^\d+$/})
  }
}