<script lang="ts">
  import { onMount } from "svelte"

  import connection_orb from "@assets/images/connection-orb.png"
  import example_orb from "@assets/images/example-orb.png"
  import voice_orb from "@assets/images/voice-orb.png"
  import { S } from "vite/dist/node/types.d-AKzkD8vd"

  const ORBS = {
    connection: connection_orb,
    example: example_orb,
    voice: voice_orb,
  }

  export let players = []
  export let leaderships = []
  export let radius = 350
  export let clickPlayers = true

  type LeadershipTypeExt = LeadershipType & { count: number }

  let selectedPlayer: any
  let open = false

  type SliceType = {
    path: string
    color: string
    code: string
    name: string
    startX: number
    startY: number
    endX: number
    endY: number
    startAngle: number
    endAngle: number
    percentage: string
  }

  function createPieChart(
    data: LeadershipTypeExt[],
    total: number,
    size: number,
  ): SliceType[] {
    let startAngle = 0
    const radius = size / 2
    const center = size / 2

    return data.map((item, index) => {
      const sliceAngle = (item.count / total) * 2 * Math.PI
      const endAngle = startAngle + sliceAngle

      // Calculate the two points on the arc
      const startX = center + radius * Math.cos(startAngle)
      const startY = center + radius * Math.sin(startAngle)
      const endX = center + radius * Math.cos(endAngle)
      const endY = center + radius * Math.sin(endAngle)

      // Create the SVG path
      const largeArcFlag = sliceAngle > Math.PI ? 1 : 0
      const path = [
        `M ${center},${center}`,
        `L ${startX},${startY}`,
        `A ${radius},${radius} 0 ${largeArcFlag},1 ${endX},${endY}`,
        "Z",
      ].join(" ")

      // Prepare the slice data
      const slice = {
        path,
        color: item.color,
        code: item.code,
        name: item.name,
        startX,
        startY,
        endX,
        endY,
        startAngle,
        endAngle,
        percentage: ((item.count / total) * 100).toFixed(1),
      }

      startAngle = endAngle
      return slice
    })
  }

  leaderships.forEach((leadership) => {
    leadership.count = players.filter(
      (p) => p.result.leadership?.code === leadership.code,
    ).length
  })

  function setPlayersPos(
    item: any,
    players: any[],
    radius: number,
    maxWidth: number,
  ): void {
    const STEP = maxWidth >= 50 ? 2 : 3
    let step = STEP

    const WIDTH_SPACE = 11 + maxWidth
    const ANGLE_ADD =
      Math.PI /
      (() => {
        //if (players.length < 20 && maxWidth >= 50) return 6
        if (players.length < 20) return 8
        if (players.length < 30) return 8.2
        if (players.length < 40) return 10.9
        if (players.length < 50) return 11
        if (players.length < 60) return 11.7
        if (players.length < 70) return 11.9
        if (players.length < 90) return 12.1
        return 14
      })()

    let x = 0
    let y = 0
    const calcX = (stp: number, ang: number) => {
      return radius + Math.cos(ang) * WIDTH_SPACE * stp // + addS
    }
    const calcY = (stp: number, ang: number) => {
      let subs = ang > Math.PI / 2 ? WIDTH_SPACE / 2 : 0
      return radius + Math.sin(ang) * WIDTH_SPACE * stp - subs
    }

    let angle = item.angleStart
    let line = 0

    players.forEach((player, idx) => {
      if (idx === 0) angle = item.startAngle + 0.15
      x = calcX(step, angle)
      y = calcY(step, angle)

      player.x = x - 18
      player.y = y - 10
      player.color = item.color
      player.size = maxWidth

      if (isNaN(x) || isNaN(y)) {
        console.log(
          "NAN",
          player.name,
          player.result.leadership.code,
          "step",
          step,
          "angle",
          angle,
          x,
          y,
        )
      }

      if ((step + 2) * WIDTH_SPACE > radius + 20) {
        step = STEP
        line++
        angle = item.startAngle + line * ANGLE_ADD
        console.log(
          "new step",
          step,
          angle,
          "x",
          Math.cos(angle),
          "y",
          Math.sin(angle),
        )
      } else {
        step++
      }
    })
  }

  const RADIUS = radius
  let total = leaderships.reduce((acc, leadership) => acc + leadership.count, 0)

  const MAX_ITEM_WIDTH = ((tot: number) => {
    if (tot > 30 && tot < 50) return 60
    if (tot > 50 && tot < 80) return 50
    if (tot > 80 && tot < 120) return 40
    if (tot > 120) return 30
    return 70
  })(total)

  function showDetail(player: UserType): void {
    selectedPlayer = player
    open = true
  }

  let filteredLeaderships = leaderships.filter((l) => l.count > 0)
  let graphItems = createPieChart(filteredLeaderships, total, RADIUS * 2)

  graphItems.forEach((item) => {
    let filteredPlayers = players.filter(
      (p) => p.result.leadership?.code === item.code,
    )
    setPlayersPos(item, filteredPlayers, RADIUS, MAX_ITEM_WIDTH)
  })

  onMount(() => {
    setTimeout(() => {
      document.addEventListener("click", (e) => {
        if (!e.target?.id?.toString().includes("image-")) {
          open = false
        }
      })
    }, 500)
  })
</script>

<div class="flex">
  <div class="flex-1"></div>
  <div class="relative">
    <svg
      width={RADIUS * 2}
      height={RADIUS * 2}
      class="mx-auto"
      xmlns="http://www.w3.org/2000/svg"
    >
      {#each graphItems as item}
        <path d={item.path} fill={item.color} id="pie-{item.code}" />
      {/each}
      {#if graphItems.length === 1}
        <circle r={RADIUS} cx={RADIUS} cy={RADIUS} fill={graphItems[0].color} />
      {/if}

      {#each players as player}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <foreignObject
          x={player.x}
          y={player.y}
          width={player.size}
          height={player.size}
          class="cursor-pointer"
          on:click={() => showDetail(player)}
        >
          <img
            src={player.avatar_img}
            style="border-radius:50%;border:4px solid {player.color};"
            width={player.size}
            height={player.size}
            alt={player.name}
            id="image-{player.id}"
            class="cursor-pointer"
          />
        </foreignObject>
      {/each}
    </svg>

    <div
      class="absolute bg-white p-4 rounded-md border border-gray-300 z-10 whitespace-nowrap space-y-2 shadow-lg"
      id="player-detail"
      class:hidden={!open}
      style="top: {selectedPlayer?.y + 40}px; left: {selectedPlayer?.x}px;"
    >
      <div class="flex items-center gap-2">
        <span
          class="size-6 inline-block"
          style="background:{selectedPlayer?.result.leadership.color}"
        />
        {selectedPlayer?.name}
      </div>
      <div class="flex items-center gap-2">
        {#if selectedPlayer?.result?.captain}
          <span
            title="Captain"
            class="bg-blue-500 text-white font-semibold size-4 text-sm flex place-items-center p-1"
          >
            c
          </span>
        {/if}
        {selectedPlayer?.result?.position || ""}
      </div>
    </div>
  </div>
  <div class="flex-1"></div>
</div>

{#if clickPlayers}
  <div class="text-gray-500 text-sm font-semibold text-center">
    Click on the players
  </div>
{:else}
  <div class="h-6"></div>
{/if}

<div class="flex">
  <div class="flex-1"></div>
  <div class="space-y-4 pt-4">
    {#each leaderships as leadership}
      <div class="flex items-center gap-2">
        <img src={ORBS[leadership.code]} class="size-6" alt={leadership.name} />

        <span class="font-semibold">
          {leadership.name}
        </span>
      </div>
    {/each}
  </div>
  <div class="flex-1"></div>
</div>
